import React, { useEffect } from 'react'
import styled from 'styled-components'

const Shop = () => {
  let mounted = false
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://shopmy.social/embed/hortonlane/'
    script.onload = () => {
      window.ShopMySocial.init()
    }
    if (!mounted) {
      document.body.appendChild(script)
      mounted = true
    }
  }, [])
  return (
    <div id="sms-widget">
      <Widget>
        Loading...
      </Widget>
    </div>
  )
}

export default Shop

const Widget = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`
